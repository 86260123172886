<template>
    <div class="invoice-container">
        <div
            class="invoice"
            :class="{
                waiting: invoice.status === 1,
            }"
            @click="handleClickInvoice"
        >
            <el-tooltip
                v-if="invoice_status"
                placement="top"
                :content="icon_tooltip"
            >
                <invoice-status-icon
                    :status="invoice_status"
                    :due="due_status"
                    class="icon"
                />
            </el-tooltip>

            <span class="sequence">
                Invoice
                <strong>{{ invoice.sequence }}/{{ invoices_count }}</strong>
            </span>

            <hr />

            <span
                class="reference"
                :class="{unscheduled: unscheduled && !sent}"
            >
                <span class="number" v-if="invoice.xero">
                    {{ invoice.xero.invoice_number }}
                </span>

                {{ invoice.invoice_id ? invoice.reference : reference_preview }}
            </span>

            <hr />

            <span class="status">{{ invoice.total | currency }}</span>
        </div>

        <arrow-spacer size="small" :class="{hidden: !consecutive}" />
    </div>
</template>

<script>
import ArrowSpacer from '@/components/generic/ArrowSpacer';
import InvoiceStatusIcon from '@/components/generic/InvoiceStatusIcon';

import ReferenceTemplateMixin from '@/mixins/referencetemplate.mixin';

import moment from 'moment-timezone';
import {momentWithTz} from '@/utils';

import {DUE_STATUS, INVOICE_STATUS} from '@/enums';

export default {
    name: 'paymentplan-invoice-block',
    components: {
        ArrowSpacer,
        InvoiceStatusIcon,
    },
    mixins: [ReferenceTemplateMixin],
    props: {
        invoice: {
            type: Object,
            required: true,
        },
        invoices_count: {
            type: Number,
            required: true,
        },
        project_ref: {
            type: String,
            default: '',
        },
        estimate: {
            type: Object,
            default: null,
        },
        consecutive: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            new_window_pointer: false,
        };
    },
    computed: {
        projectRef() {
            // overrides default behaviour of processTemplate mixin
            return this.project_ref;
        },
        reference_preview() {
            let reference = this.processTemplate(
                this.invoice.reference_template || this.invoice.reference,
                this.invoice.is_scheduled
                    ? this.invoice.schedule_date
                    : moment(),
                this.invoice.sequence,
                this.invoices_count
            );

            return reference;
        },
        invoice_status() {
            if (this.invoice.invoice_id) {
                const _invoice = this.$store.getters.invoiceWithId(
                    this.invoice.invoice_id
                );

                if (_invoice) {
                    if (
                        _invoice.status === INVOICE_STATUS.AUTHORISED &&
                        _invoice.sent_to_contact
                    )
                        return INVOICE_STATUS.SENT;

                    return _invoice.status;
                }
            }

            if (this.invoice.is_scheduled) return INVOICE_STATUS.SCHEDULED;
            return INVOICE_STATUS.PENDING;
        },
        icon_tooltip() {
            let status_label = '';

            switch (this.invoice_status) {
                case INVOICE_STATUS.PENDING:
                    status_label = 'Pending';
                    break;
                case INVOICE_STATUS.SCHEDULED:
                    const date = this.invoice.schedule_date?.seconds
                        ? this.invoice.schedule_date.toDate()
                        : this.invoice.schedule_date;
                    status_label =
                        'Scheduled ' + moment(date).format('DD/MM/YYYY');
                    break;
                case INVOICE_STATUS.SENT:
                    status_label = 'Sent';
                    break;
                case INVOICE_STATUS.PAID:
                    status_label = 'Paid';
                    break;
                default: {
                    status_label = this.$options.filters.capitalize(
                        this.invoice_status
                    );
                    break;
                }
            }

            if (this.invoice_status === INVOICE_STATUS.SENT) {
                switch (this.due_status) {
                    case DUE_STATUS.DUE:
                        status_label = status_label.concat(' - Due');
                        break;
                    case DUE_STATUS.OVERDUE:
                        status_label = status_label.concat(' - Overdue');
                        break;
                    default:
                        break;
                }
            }

            return status_label;
        },
        unscheduled() {
            return !this.invoice.is_scheduled && !this.invoice.invoice_id;
        },
        sent() {
            return !!this.invoice.invoice_id;
        },
        days_to_schedule() {
            if (this.invoice.is_scheduled) {
                const today = momentWithTz().startOf('day');
                const scheduled = momentWithTz(
                    this.invoice.schedule_date.seconds
                        ? this.invoice.schedule_date.seconds * 1000
                        : this.invoice.schedule_date
                );
                return scheduled.diff(today, 'days');
            }

            return null;
        },
        due_status() {
            if (this.invoice_status === INVOICE_STATUS.SENT) {
                const _invoice = this.$store.getters.invoiceWithId(
                    this.invoice.invoice_id
                );
                const today = momentWithTz().startOf('day');
                const due = momentWithTz(_invoice.due_date, 'DD/MM/YYYY');
                if (due.isBefore(today)) return DUE_STATUS.OVERDUE;
                return DUE_STATUS.PENDING;
            }
            if (this.days_to_schedule < 0) return DUE_STATUS.OVERDUE;
            if (this.days_to_schedule < 4) return DUE_STATUS.DUE;
            return DUE_STATUS.PENDING;
        },
    },
    methods: {
        handleClickInvoice(event) {
            // request new window if clicked with meta key
            this.$emit('click', this.invoice, event.metaKey);
        },
    },
};
</script>

<style lang="scss" scoped>
.invoice-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;

    .invoice {
        height: 80px;
        min-width: 200px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid $border-grey;
        cursor: pointer;
        background-color: white;
        box-sizing: border-box;
        position: relative;
        z-index: 5;
        line-height: initial;

        span.sequence {
            font-size: 11px;
            display: block;
        }

        span.number {
            font-size: 11px;
            display: inline-block;
            font-weight: bold;
            margin-right: 10px;
        }

        span.reference {
            display: block;
            font-size: 11px;

            &.unscheduled {
                opacity: 0.25;
            }
        }

        hr {
            width: 50%;
            margin: 4px 0;
            border: none;
            height: 1px;
            background-color: rgba(220, 223, 230, 0.4);
        }

        span.status {
            font-size: 12px;
            display: block;
            font-family: 'Rubik', sans-serif;

            strong {
                font-size: 15px;
                margin: 0 4px;
            }
        }

        &:hover {
            box-shadow: 0 0 0 1px $blue !important;
            border: 1px solid $blue !important;
        }

        &.waiting {
            border: 1px solid $border-grey-light;
            box-sizing: border-box;

            span {
                opacity: 0.3;
            }

            .icon {
                display: none;
            }
        }

        .icon {
            position: absolute;
            top: -5px;
            right: -5px;
        }
    }

    .spacer.hidden {
        visibility: hidden;
    }
    &:last-child .spacer {
        display: none;
    }
}
</style>
