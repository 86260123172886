<template>
    <div class="paymentplan-invoice-form">
        <div class="paymentplan-invoice-form-header">
            <el-row :gutter="5" type="flex" align="middle">
                <el-col :span="ref_width" class="reference">
                    Reference
                </el-col>

                <el-col :span="4" v-if="has_purchase_order">
                    Purchase order
                </el-col>

                <el-col :span="3">
                    <span v-if="is_invoiced || !is_scheduled">
                        Invoice date
                    </span>
                    <span v-else>Scheduled date</span>
                </el-col>

                <el-col :span="3">
                    Due date
                </el-col>

                <el-col v-if="!is_invoiced" :span="7">
                    Due date terms
                </el-col>
            </el-row>
        </div>

        <div class="paymentplan-invoice-form-row">
            <el-row :gutter="5" type="flex" align="middle">
                <el-col :span="ref_width" class="reference end-field">
                    <el-input
                        v-show="editing_ref && !is_invoiced"
                        ref="referenceField"
                        v-model="form.reference_template"
                        class="input-value left"
                        @blur="
                            () => {
                                editReference(false);
                            }
                        "
                    />
                    <div
                        v-show="!editing_ref || is_invoiced"
                        class="el-input__inner input-value left preview editable"
                        :class="{disabled: is_invoiced}"
                        @click="
                            () => {
                                editReference(true);
                            }
                        "
                    >
                        {{ reference_preview }}
                        <i v-if="!is_invoiced" class="edit-icon el-icon-edit" />
                    </div>
                </el-col>

                <el-col :span="4" v-if="has_purchase_order">
                    <div
                        class="el-input__inner input-value left disabled block"
                    >
                        {{ payment_plan.purchase_order }}
                    </div>
                </el-col>

                <el-col :span="3" class="center end-field">
                    <el-date-picker
                        v-model="form.schedule_date"
                        class="input-value"
                        :class="{invalid: form.schedule_date === null}"
                        :clearable="false"
                        format="dd/MM/yyyy"
                        :disabled="is_invoiced"
                        :picker-options="picker_options"
                        @change="handleRecalculateDueDate"
                    />
                </el-col>

                <el-col :span="3" class="center end-field">
                    <el-date-picker
                        v-model="form.due_date"
                        class="input-value"
                        format="dd/MM/yyyy"
                        :disabled="is_invoiced"
                        @change="handleDueDateChange"
                    />
                </el-col>

                <template v-if="!is_invoiced">
                    <el-col :span="2">
                        <el-input-number
                            v-model="form.due_value"
                            class="input-value"
                            :controls="false"
                            :min="1"
                            @change="handleRecalculateDueDate"
                        />
                    </el-col>
                    <el-col :span="5">
                        <el-select
                            v-model="form.due_terms"
                            @change="handleRecalculateDueDate"
                        >
                            <el-option
                                :value="PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_DATE"
                                label="days after invoice date"
                            />
                            <el-option
                                :value="
                                    PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_END_OF_MONTH
                                "
                                label="days after end of invoice month"
                            />
                            <el-option
                                :value="PAYMENT_PLAN_DUE_TERMS.DAY_OF_MONTH"
                                label="of the current month"
                            />
                            <el-option
                                :value="
                                    PAYMENT_PLAN_DUE_TERMS.DAY_OF_NEXT_MONTH
                                "
                                label="of the following month"
                            />
                        </el-select>
                    </el-col>
                </template>
            </el-row>
        </div>

        <div class="paymentplan-line-item-header">
            <el-row :gutter="5" type="flex" align="middle">
                <el-col :span="description_width" class="description">
                    Line item
                </el-col>

                <el-col v-if="draft" :span="3" class="center">
                    Total to Invoice
                </el-col>

                <el-col
                    v-if="draft && !single_invoice"
                    :span="2"
                    class="center"
                >
                    % this invoice
                </el-col>

                <el-col :span="3" class="center">
                    Invoice amount
                </el-col>
            </el-row>
        </div>

        <div class="paymentplan-line-items">
            <payment-plan-invoice-line-item
                v-for="line_item in invoice.line_items"
                :key="line_item.id"
                :line_item="line_item"
                :draft="draft"
                :show_percent="!single_invoice"
                @update="updateLineItem"
            />
        </div>

        <div class="paymentplan-line-item-footer">
            <el-row :gutter="5" type="flex" align="middle">
                <el-col :span="21" class="right">
                    Total
                </el-col>

                <el-col
                    :span="3"
                    class="center"
                    :class="{invalid: invoice_total < 0}"
                >
                    {{ invoice_total | currency }}
                </el-col>
            </el-row>
        </div>

        <div class="paymentplan-footer">
            <template v-if="has_options">
                <hr />

                <span
                    style="
                        font-weight: bolder;
                        font-size: 10px;
                        margin-bottom: 5px;
                    "
                >
                    Options:
                </span>
                <el-row v-if="!is_invoiced">
                    <el-switch
                        configured
                        for
                        emailing
                        v-model="is_scheduled"
                        active-text="Send automatically"
                    />
                </el-row>

                <el-row>
                    <template v-if="is_invoiced">
                        <span
                            v-if="form.is_gst_included"
                            style="font-size: 13px;"
                        >
                            <i class="el-icon-warning" /> GST included
                        </span>
                    </template>
                    <el-switch
                        active-text="GST included"
                        v-else
                        v-model="form.is_gst_included"
                    />
                </el-row>
            </template>

            <hr />

            <reminder-list
                :reminders="form.reminders"
                @change="handleChangeReminders"
            />
        </div>
    </div>
</template>

<script>
import PaymentPlanInvoiceLineItem from './PaymentPlan_invoice_line_item';
import ReferenceTemplateMixin from '@/mixins/referencetemplate.mixin';
import PaymentPlanMixin from '@/mixins/paymentplan.mixin';

import {PAYMENT_PLAN_DUE_TERMS} from '@/enums';
import dayjs from 'dayjs';

import ReminderList from '@/pages/paymentplans/components/Reminder_list.vue';

export default {
    name: 'paymentplan-invoice',
    components: {
        ReminderList,
        PaymentPlanInvoiceLineItem,
    },
    mixins: [ReferenceTemplateMixin, PaymentPlanMixin],
    props: {
        payment_plan: {
            type: Object,
            required: true,
        },
        invoice: {
            type: Object,
            required: true,
        },
        estimate: {
            type: Object,
            required: true,
        },
        draft: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            PAYMENT_PLAN_DUE_TERMS: PAYMENT_PLAN_DUE_TERMS,
            is_scheduled: false,
            form: {
                reference_template: '',
                schedule_date: null,
                due_date: null,
                due_value: 14,
                due_terms: PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_DATE,
                reminders: [],
                is_gst_included: false,
            },
            editing_ref: false,
            picker_options: {
                shortcuts: [
                    {
                        text: 'Today',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        },
                    },
                    {
                        text: 'Next month',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(dayjs().add(1, 'month').toDate());
                            picker.$emit('pick', date);
                        },
                    },
                    {
                        text: '1st of month',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(
                                dayjs()
                                    .add(1, 'month')
                                    .startOf('month')
                                    .toDate()
                            );
                            picker.$emit('pick', date);
                        },
                    },
                ],
            },
        };
    },
    computed: {
        ref_width() {
            let width = 7;
            if (this.is_invoiced) width = 16;
            if (!this.has_purchase_order) width += 4;
            return width;
        },

        description_width() {
            let width = this.draft ? 16 : 21;
            if (this.single_invoice) width += 2;
            return width;
        },

        reference_preview() {
            return this.processTemplate(
                this.form.reference_template,
                this.form.schedule_date ?? dayjs(),
                this.invoice.sequence,
                this.payment_plan.quantity
            );
        },

        invoice_total() {
            return this.invoice.line_items.reduce((sum, item) => {
                const sign = item.credit ? -1 : 1;
                return (sum +=
                    item.amount * ((100 - (item.discount || 0)) / 100) * sign);
            }, 0);
        },
        is_invoiced() {
            return !!this.invoice.invoice_id;
        },
        has_purchase_order() {
            return !!this.payment_plan.purchase_order;
        },
        single_invoice() {
            return this.payment_plan.quantity === 1;
        },

        has_options() {
            var options = 0;

            if (!this.is_invoiced) options++;
            else {
                if (this.form.is_gst_included) options++;
            }

            return !!options;
        },
    },
    watch: {
        invoice: {
            handler(invoice) {
                this.is_scheduled = invoice.is_scheduled;
                // use template if set, otherwise use reference (for legacy support)
                this.form.reference_template =
                    invoice.reference_template || invoice.reference;
                this.form.schedule_date = invoice.schedule_date;
                this.form.due_date = invoice.due_date;
                this.form.due_value = invoice.due_value;
                this.form.due_terms = invoice.due_terms;
                this.form.is_gst_included = invoice.is_gst_included;
                // workaround Vuefire loading nested references as strings >:(
                this.form.reminders = invoice.reminders
                    ? invoice.reminders.map((reminder) => {
                          return {
                              created_by: this.$fire.doc(
                                  `users/${this.$options.filters.fireRef2id(
                                      reminder.created_by
                                  )}`
                              ),
                              user: this.$fire.doc(
                                  `users/${this.$options.filters.fireRef2id(
                                      reminder.user
                                  )}`
                              ),
                              date: reminder.date,
                          };
                      })
                    : [];
            },
            immediate: true,
        },
        'payment_plan.reference'(ref) {
            this.form.reference_template = ref;
        },
        is_scheduled() {
            this.invoiceUpdated();
        },
        // checking all form values independently,
        // because a deep watch on the whole form object
        // would always trigger on the reminders array
        'form.reminders': {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    // reminders should only be added or removed, not modified,
                    // so only checking for change to array length here
                    if (newVal.length !== oldVal.length) {
                        // autosave after a change to reminders
                        this.invoiceUpdated(true);
                    }
                }
            },
        },
        'form.reference_template'() {
            this.invoiceUpdated();
        },
        'form.schedule_date'() {
            this.invoiceUpdated();
        },
        'form.due_date'() {
            this.invoiceUpdated();
        },
        'form.due_value'() {
            this.invoiceUpdated();
        },
        'form.due_terms'() {
            this.invoiceUpdated();
        },
        'form.is_gst_included'() {
            this.invoiceUpdated();
        },
    },
    methods: {
        updateLineItem(lineItem) {
            // replace existing invoice line item with new data
            const index = this.invoice.line_items.findIndex((li) => {
                return (
                    this.$options.filters.fireRef2id(li.estimate_group) ===
                    this.$options.filters.fireRef2id(lineItem.estimate_group)
                );
            });

            if (index >= 0) {
                this.invoice.line_items[index] = lineItem;
            }

            this.invoiceUpdated();
        },
        invoiceUpdated(autosave = false) {
            let reference = null;
            // schedule date is now mandatory, recalculate template on any update
            if (this.form.reference_template && this.form.schedule_date) {
                reference = this.processTemplate(
                    this.form.reference_template,
                    this.form.schedule_date,
                    this.invoice.sequence,
                    this.payment_plan.quantity
                );
            }

            this.$emit(
                'update',
                {
                    ...this.invoice,
                    is_scheduled: this.is_scheduled,
                    is_gst_included: !!this.form.is_gst_included,
                    reference_template: this.form.reference_template,
                    reference,
                    schedule_date: this.form.schedule_date,
                    due_date: this.form.due_date,
                    due_value: this.form.due_value,
                    due_terms: this.form.due_terms,
                    line_items: this.invoice.line_items,
                    reminders: this.form.reminders,
                },
                autosave
            );
        },
        handleRecalculateDueDate() {
            this.form.due_date = this.calculateDueDate(
                this.form.schedule_date,
                this.form.due_value,
                this.form.due_terms
            );
        },
        handleDueDateChange() {
            if (this.form.due_date === null) {
                setTimeout(this.handleRecalculateDueDate);
            }
        },
        editReference(editing = true) {
            this.editing_ref = editing;
            if (editing) {
                setTimeout(this.$refs.referenceField.focus);
            }
        },
        handleChangeReminders(reminders) {
            this.form.reminders = reminders;
        },
    },
};
</script>

<style lang="scss" scoped>
.paymentplan-invoice-form {
    min-width: 810px;
    border-radius: 4px;
    padding: 10px 20px 20px 20px;
    border: 1px solid $border-grey-light;
    background-color: $grey;

    .paymentplan-invoice-form-header,
    .paymentplan-line-item-header {
        font-size: 11px;
        margin: 10px 0 6px 0;
    }

    .paymentplan-invoice-form-row {
        ::v-deep .input-value {
            width: 100%;

            &.preview {
                border: 1px solid $border-grey !important;
                font-size: 13px;

                &.editable {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    pointer-events: all;
                    cursor: pointer;

                    &.disabled {
                        cursor: default;
                    }
                }
            }

            input {
                padding-left: 5px !important;
                padding-right: 5px !important;
                text-align: center;
            }

            &.left input {
                text-align: left;
            }

            &.block {
                display: block;
            }
        }

        .el-select {
            width: 100%;
        }

        .edit-icon {
            line-height: 40px;
            font-size: 18px;
        }

        .save-icon {
            line-height: 40px;
            font-size: 18px;
            padding-right: 10px;
        }
    }

    .paymentplan-line-item-footer {
        font-size: 11px;
        font-weight: bold;
        margin: 6px 0 20px;
    }

    .paymentplan-footer {
        hr {
            margin: 15px 0;
            border: none;
            height: 1px;
            background-color: $border-grey-light;
        }

        .el-row {
            margin: 5px 0;
        }
    }

    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    .invalid {
        color: $red;
    }

    ::v-deep .el-switch__label * {
        font-size: 11px;
    }
}
</style>
